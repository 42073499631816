import React from 'react'
import styles from '../Bookings/EachBookingWrapper.module.css'

const EachBookingWrapper = () => {
    return (
        <div>
            Your visit history will be live soon!
        </div>
    )
}

export default EachBookingWrapper