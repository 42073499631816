import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import MainLoader from '../../Components/UI/MainLoader';
import { monthNumToTxtSmall, toDayMonthYear } from '../../Helper/Calculations';
import { BASE_URL_API } from '../../References/constants';
import AuthContext from '../../store/auth-context';
import styles from '../GiftCards/CardDetailsTransactions.module.css'

const CardDetailsTransactions = props => {
    const cardIdentifier = props.cardIdentifier;
    const authCtx = useContext(AuthContext)
    const [isLoadingTransactions, setIsLoadingTransactions] = useState(true)
    const [allTransactions, setAllTransactions] = useState([])

    const getAllCardsTransactions = async () => {
        setIsLoadingTransactions(true)
        const getAllCardsTransactionsResponse = await fetch(BASE_URL_API + "/getAllCardsTransactions",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    cardIdentifier: cardIdentifier
                })
            });

        if (!getAllCardsTransactionsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllCardsTransactionsRespo = await getAllCardsTransactionsResponse.json();
            if (getAllCardsTransactionsRespo.status === 'success') {
                setAllTransactions(getAllCardsTransactionsRespo.response)
            } else {
                if (getAllCardsTransactionsRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {
                    setAllTransactions([])
                    console.log(getAllCardsTransactionsRespo)
                }
            }
        }
        setIsLoadingTransactions(false)
    }


    useEffect(() => {
        getAllCardsTransactions()
    }, [cardIdentifier])


    console.log(allTransactions)

    return (
        <div className={styles.mainWrapper}>
            <MainLoader isVisible={isLoadingTransactions} />
            {/* CardDetailsTransactions */}
            <div className={styles.allTransactionsWrapper}>
                <div className={styles.allTransactionsContainer}>
                    <div className={styles.allTransactionsHeader}>
                        <div>All Transactions</div>
                        <div>-- </div>
                    </div>
                    {isLoadingTransactions === false ?
                        <div className={styles.allTransactionsBody}>
                            {allTransactions.map((item, index) => (
                                item.transactionType === "debit" ?
                                    <Link to={"/visits/" + item.relatedSessionIdentifier} key={index} >
                                        <div className={styles.eachTransactionWrapper}>
                                            <div>
                                                <div className={styles.dateIcon}>
                                                    <div className={styles.dateTxt}>
                                                        {toDayMonthYear(item.transactionDate)[0]}
                                                    </div>
                                                    <div className={styles.monthTxt}>
                                                        {monthNumToTxtSmall(toDayMonthYear(item.transactionDate)[1])}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className={styles.transactionAmount}>
                                                    {item.transactionType === "credit" ? "+" : "-"}&#8377;{item.transactionAmount}
                                                </div>
                                                <div className={styles.currentBalance}>
                                                    &#8377;{item.currentBalance}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    :
                                    <div key={index} className={styles.eachTransactionWrapper}>
                                        <div>
                                            <div className={styles.dateIcon}>
                                                <div className={styles.dateTxt}>
                                                    {toDayMonthYear(item.transactionDate)[0]}
                                                </div>
                                                <div className={styles.monthTxt}>
                                                    {monthNumToTxtSmall(toDayMonthYear(item.transactionDate)[1])}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className={styles.transactionAmount}>
                                                {item.transactionType === "credit" ? "+" : "-"}&#8377;{item.transactionAmount}
                                            </div>
                                            <div className={styles.currentBalance}>
                                                &#8377;{item.currentBalance}
                                            </div>
                                        </div>
                                    </div>
                            ))}
                        </div>
                        :
                        <div className={styles.allTransactionsBody}>
                            <div className={styles.transactionsBeingLoaded}>
                                <div>
                                    Hold On,
                                </div>
                                <div>
                                    Your transactions are being loaded..
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default CardDetailsTransactions