import React from 'react'
import { Link } from 'react-router-dom';
import styles from '../GiftCards/EachCard.module.css'

const EachCard = props => {
    const item = props.item;
    return (
        <Link to={"/mycards/" + item.issuedCardNumber}  >
            <div className={styles.mainWrapper} style={{ backgroundImage: `url(${item.coverImage})` }}>
                <div className={styles.cardNumberTxt}>
                    {item.issuedCardNumber.substring(0, 4)} &nbsp;
                    {item.issuedCardNumber.substring(4, 8)} &nbsp;
                    {item.issuedCardNumber.substring(8, 12)} &nbsp;
                    {item.issuedCardNumber.substring(12, 16)}
                </div>
            </div>
        </Link>
    )
}

export default EachCard