import React, { useState } from 'react'
import styles from '../GiftCards/ValidationViaPhone.module.css'

const ValidationViaPhone = props => {
    let otpRequested = props.otpRequested;
    const sendOtpHandler = props.sendOtpHandler;
    const [phoneNumber, setPhoneNumber] = useState('');

    const enterPhoneHandler = (k) => {
        if (k.length === 10) {
            sendOtpHandler(true, k)
        }
        setPhoneNumber(k)

    }


    return (
        otpRequested === false ?
            <div className={styles.mainWrapper}>
                <div className={styles.inputWrapper}>
                    <input
                        placeholder="+91-XXX-XXX-XXXX"
                        onChange={(e) => enterPhoneHandler(e.target.value)}
                        value={phoneNumber}
                        type='tel'
                        maxLength={10}
                    />

                </div>
                <div className={styles.sendOtpWrapper}>
                    <button>Send OTP</button>
                </div>
            </div>
            :
            <div className={styles.mainWrapper}>
                <div className={styles.sentOtp}>
                    <div>
                        {phoneNumber}
                    </div>
                    <div onClick={() => sendOtpHandler(false)}>
                        Change
                    </div>
                </div>
            </div>
    )
}

export default ValidationViaPhone