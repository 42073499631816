export const toDayMonthYear = (k) => {
    let date = k.split(" ")[0];
    let dd = date.split("-")[2];
    let mm = date.split("-")[1];
    let yyyy = date.split("-")[0];

    let returningArr = [dd, mm, yyyy];

    return returningArr;

}


export const monthNumToTxtSmall = (k) => {
    if(k==='01') { return 'Jan' }
    if(k==='02') { return 'Feb' }
    if(k==='03') { return 'Mar' }
    if(k==='04') { return 'Apr' }
    if(k==='05') { return 'May' }
    if(k==='06') { return 'Jun' }
    if(k==='07') { return 'Jul' }
    if(k==='08') { return 'Aug' }
    if(k==='09') { return 'Sep' }
    if(k==='10') { return 'Oct' }
    if(k==='11') { return 'Nov' }
    if(k==='12') { return 'Dec' }
}