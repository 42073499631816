import React from 'react'
import styles from '../Pages/Home.module.css'

const Home = () => {
    return (
        <div>
            Welcome to Lokaci - The Salon Company
        </div>
    )
}

export default Home