import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import styles from '../Headers/GiftCardVerifyHeader.module.css'
import AuthContext from '../store/auth-context';


const GiftCardVerifyHeader = () => {
    const authCtx = useContext(AuthContext);


    if (authCtx.isLoggedIn) {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    <div className={styles.leftCol}>
                        Lokaci
                    </div>
                    <div className={styles.rightCol}>
                        <div onClick={() => authCtx.logout()} className={styles.supportBtn}

                        >
                            Logout
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    <div className={styles.leftCol}>
                        Lokaci - The Salon Company
                    </div>
                    <div className={styles.rightCol}>
                        <a href="tel:+91-8929460356" className={styles.supportBtn}>
                            Support
                        </a>
                    </div>
                </div>
            </div>
        )
    }

}

export default GiftCardVerifyHeader