import React, { useContext, useEffect, useState } from 'react'
import EachCard from '../../Components/GiftCards/EachCard'
import MainLoader from '../../Components/UI/MainLoader'
import GiftCardVerifyHeader from '../../Headers/GiftCardVerifyHeader'
import { BASE_URL_API } from '../../References/constants'
import AuthContext from '../../store/auth-context'
import styles from '../GiftCards/PurchasedCards.module.css'

const PurchasedCards = props => {
    const authCtx = useContext(AuthContext);
    const [allCards, setAllCards] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const getMyPurchasedCards = async () => {
        setIsLoading(true);
        const getMyPurchasedCardsResponse = await fetch(BASE_URL_API + "/getMyPurchasedCards",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    userIdentifier: authCtx.userIdentifier
                })
            });

        if (!getMyPurchasedCardsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getMyPurchasedCardsRespo = await getMyPurchasedCardsResponse.json();
            if (getMyPurchasedCardsRespo.status === 'success') {
                setAllCards(getMyPurchasedCardsRespo.response)
            } else {
                if (getMyPurchasedCardsRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else if (getMyPurchasedCardsRespo.message === "no cards found") {
                    setAllCards([])
                } else {
                    setAllCards([])
                    console.log(getMyPurchasedCardsRespo)
                }
            }
        }
        setIsLoading(false)
    }

    useEffect(() => {
        getMyPurchasedCards()
    }, [authCtx.userIdentifier])

    console.log(allCards);


    return (
        <div className={styles.mainWrapper}>
            <MainLoader isVisible={isLoading} />
            <GiftCardVerifyHeader />

            {isLoading !== true ?
                <div className={styles.mainBodyWrapper}>
                    {allCards.length > 0 ?
                        <div className={styles.allCardsWrapper}>
                            {allCards.map((item, index) => (
                                <EachCard item={item} key={index} />
                            ))}
                            <div className={styles.buyANewCardWrapper}>
                                <div className={styles.buyNewCardContainer}>
                                    <div className={styles.plusIcon}>
                                        +
                                    </div>
                                    <div className={styles.buyNewCardTxt}>
                                        Buy a New Card 
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className={styles.noCardPurchased}>
                            <div className={styles.notPurchasedTxt}>
                                You haven't purchased any membership cards.
                            </div>
                            <div className={styles.willAppearHereTxt}>
                                Your purchased cards will appear here!
                            </div>
                        </div>
                    }
                </div>
                :
                <div className={styles.mainBodyWrapper}>

                </div>
            }

        </div>
    )
}

export default PurchasedCards