import React from 'react'
import { monthNumToTxtSmall, toDayMonthYear } from '../../Helper/Calculations';
import styles from '../GiftCards/CardDetailsWrapper.module.css'

const CardDetailsWrapper = props => {
    const cardDetails = props.cardDetails;


    if (cardDetails === "") {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div style={{ backgroundImage: `url(${cardDetails.coverImage})` }}
                    className={styles.mainCardWrapper}
                >
                </div>

                <div>
                    <div className={styles.issuedCardNumber}>
                        {cardDetails.issuedCardNumber.substring(0, 4)} &nbsp;
                        {cardDetails.issuedCardNumber.substring(4, 8)} &nbsp;
                        {cardDetails.issuedCardNumber.substring(8, 12)} &nbsp;
                        {cardDetails.issuedCardNumber.substring(12, 16)}
                    </div>
                </div>

                <div>
                    <div>
                        {cardDetails.label}
                    </div>
                    <div className={styles.issuedOnWrapper}>
                        <div className={styles.issuedOnCaption}>
                            Issued On
                        </div>
                        <div className={styles.issuedOnValue}>
                            {toDayMonthYear(cardDetails.issuedOn)[0]} {monthNumToTxtSmall(toDayMonthYear(cardDetails.issuedOn)[1])} {toDayMonthYear(cardDetails.issuedOn)[2]}
                        </div>
                    </div>

                    <div className={styles.amountPaid}>
                        &#8377;{cardDetails.amountPaid}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardDetailsWrapper