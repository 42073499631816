import React from 'react'
import styles from '../UI/MainLoader.module.css'

const MainLoader = props => {
    const isVisible = props.isVisible;

    if(isVisible===false) {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <img src="https://ltsc.lokaci.com/visual-assets/iphone-loader.gif" />
            </div>
        </div>
    )
}

export default MainLoader