import React, { useState } from 'react'
import OtpInput from 'react-otp-input';
import styles from '../GiftCards/ValidationViaCard.module.css'
import CardWrapper from './CardWrapper';
import DiamondCard from './DiamondCard';
import RubyCard from './RubyCard';

const ValidationViaCard = props => {
    const otpRequested = props.otpRequested;
    const sendOtpHandler = props.sendOtpHandler;
    const [cardType, setCardType] = useState('')


    const [selectedCard, setSelectedCard] = useState(
        {
            "cardName": "",
            "coverImage": "",
            "label": "----",
            "identifierDigits": "xxxx"
        }
    );

    const [otpPri, setOtpPri] = useState({
        otp: ''
    });

    const [cardDetails, setCardDetails] = useState([
        {
            "cardName": "Gallant",
            "coverImage": "https://ltsc.lokaci.com/visual-assets/giftcards/gallant-front.png",
            "label": "Gallant",
            "identifierDigits": "1000",
            "textColor": "#dfdfdf"
        },
        {
            "cardName": "Silver",
            "coverImage": "https://ltsc.lokaci.com/visual-assets/giftcards/silver-front.png",
            "label": "Silver",
            "identifierDigits": "2000",
            "textColor": "#00000060"
        },
        {
            "cardName": "Ruby",
            "coverImage": "https://ltsc.lokaci.com/visual-assets/giftcards/ruby-front.png",
            "label": "Ruby",
            "identifierDigits": "4000",
            "textColor": "#800080"
        },
        {
            "cardName": "Gold",
            "coverImage": "https://ltsc.lokaci.com/visual-assets/giftcards/gold-front.png",
            "label": "Gold",
            "identifierDigits": "5000",
            "textColor": "#000000"
        },
        {
            "cardName": "Diamond",
            "coverImage": "https://ltsc.lokaci.com/visual-assets/giftcards/diamond-front.png",
            "label": "Diamond",
            "identifierDigits": "6000",
            "textColor": "#fff"
        },
    ])

    const handleChangePri = (otp) => {
        setOtpPri({ otp })
        let index = cardDetails.findIndex(el => el.identifierDigits === otp.substr(0, 4));
        if (index > -1) {
            setSelectedCard(cardDetails[index]);
        } else {
            setSelectedCard({
                "cardName": "",
                // "coverImage": "https://img.freepik.com/free-vector/abstract-halftone-background_23-2148582484.jpg",
                "coverImage": "",
                "label": "----",
                "identifierDigits": "xxxx"
            })
        }
    }


    return (
        <div className={styles.eachValidationModeWrapper}>
            <div className={styles.cardWrapper}>
                <div className={styles.cardWrapperMainContainer} style={{ backgroundImage: `url(` + selectedCard.coverImage + `)`, color: selectedCard.textColor }}>
                    <div className={styles.cardTitleWrapper} style={{ color: selectedCard.textColor }}>
                        <div className={styles.pleaseEnter}>
                            {selectedCard.cardName === "" ?
                                "Please enter your card number"
                                :
                                "Lokaci"
                            }
                        </div>
                        <div className={styles.cardName} style={{ color: selectedCard.textColor }}>{selectedCard.label}</div>
                    </div>
                    <div className={styles.cardNumberWrapper}>
                        <div className={styles.cardNumberHolder}>
                            <OtpInput
                                value={otpPri.otp}
                                onChange={handleChangePri}
                                numInputs={16}
                                separator={<span></span>}
                                isInputNum={true}
                                className={styles.singleDigit}
                                containerStyle={styles.digitContainerStyle}
                                shouldAutoFocus={true}

                            />
                        </div>
                    </div>
                    {otpPri.otp.length === 16 &&
                        <div className={styles.requestOtpBtnWrapper}>
                            <div className={styles.requestOtpBtn}>
                                <button onClick={() => sendOtpHandler(true, otpPri.otp)}>Verify</button>
                            </div>
                        </div>
                    }
                </div>
            </div>


        </div>
    )
}

export default ValidationViaCard