import React, { useContext, useEffect, useState } from 'react'
import OtpInput from 'react-otp-input';
import { BASE_URL_API } from '../../References/constants';
import AuthContext from '../../store/auth-context';
import styles from '../GiftCards/ValidateOtpInput.module.css'
import MainLoader from '../UI/MainLoader';

const ValidateOtpInput = props => {
    // const [secondsLeft, setSecondsLeft] = useState(45)
    const authCtx = useContext(AuthContext);
    let secondsLeft = props.secondsLeft;
    const sendOtpHandler = props.sendOtpHandler;
    const otpSentOn = props.phone
    const sessionKey = props.sessionKey
    const [responseMessage, setResponseMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const [otp, setOtp] = useState({
        otp: ''
    });

    const handleChange = (otp) => {
        setOtp({ otp })
        if(otp.length===6) {
            // authCtx.login("heyiamthetokenprovided", "userIdentifier");
            verifyOtp(otp);
        }
    }

    const verifyOtp = async (k) => {
        console.log(k, otpSentOn)
        setIsLoading(true)
        const verifyOtpResponse = await fetch(BASE_URL_API+"/auth",
        {
            method : 'POST',
            body : JSON.stringify({
                actionType : 'verifyOtp',
                sessionKey : sessionKey,
                otpEntered : k,
                phone : otpSentOn
            })
        });

        if(!verifyOtpResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {    
            const verifyOtpRespo = await verifyOtpResponse.json();
            if(verifyOtpRespo.status==='success') {
                if(verifyOtpRespo.authToken!==null) {
                    authCtx.login(verifyOtpRespo.authToken, verifyOtpRespo.mobile)
                } else {
                    authCtx.logout()
                }
            } else {
                if(verifyOtpRespo.message==='invalidOtp') {
                    console.log("Please enter a valid otp")
                }
                setResponseMessage(verifyOtpRespo.response)
                // console.log(verifyOtpRespo)
            }
        }
        setIsLoading(false)
    }

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setSecondsLeft((p) => p - 1);
    //     }, 1000);

    //     return () => clearInterval(interval);
    // }, [])


    return (
        <div>
            <MainLoader isVisible={isLoading} />
            <div>
                <div className={styles.firstMsg}>
                    Please enter otp received on +91-{otpSentOn.substring(0, 2)}XX-X{otpSentOn.substring(5, 6)}-XX-{otpSentOn.substring(8, 10)}
                </div>
                <div>

                </div>
            </div>
            <div className={styles.otpInputWrapper}>
                <div className={styles.otpInputHolder}>
                    <OtpInput
                        value={otp.otp}
                        onChange={handleChange}
                        numInputs={6}
                        separator={<span></span>}
                        isInputNum={true}
                        shouldAutoFocus={true}
                        className={styles.otpEnterEachInput}
                    />
                </div>
                <div className={styles.didnotReceiveOtp}>
                    <div className={styles.dnroTxt}>
                        Did not receive OTP?
                    </div>
                    <div className={styles.resendOtpWrapper}>
                        {secondsLeft > 0 ?
                            <div className={styles.resendOtpInTxt}>
                                Re-send OTP in {secondsLeft} seconds
                            </div>
                            :
                            <div className={styles.resendBtn}>
                                <button
                                    onClick={() => 
                                        {
                                            sendOtpHandler(true, otpSentOn)
                                            setResponseMessage('')
                                        }
                                    }
                                >
                                    Resend OTP
                                </button>
                            </div>
                        }
                    </div>
                    <div>
                        {responseMessage}
                    </div>
                </div>
                {/* <div className={styles.validateBtnWrapper}>
                    <button>Validate</button>
                </div> */}
            </div>
        </div>
    )
}

export default ValidateOtpInput