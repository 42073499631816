import React from 'react'
import styles from './BuyCard.module.css'

const BuyCard = () => {

    return (
        <div>
            <div>
                Buy Card Now
            </div>
            <div>
                <a className={styles.payBtn}
                    href='https://pmny.in/Kr8jqUMKGYxj' >
                    Pay 1100 Now
                </a>

                <div>
                    <a className={styles.payBtn}
                        href='https://pmny.in/qrx56cWWMeJd' >
                        Buy Now </a>
                </div>
            </div>
        </div>
    )
}

export default BuyCard