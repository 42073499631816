import React, { useContext, useEffect, useState } from 'react'
import GiftCardVerifyHeader from '../../Headers/GiftCardVerifyHeader'
import styles from '../GiftCards/VerifyCards.module.css'
import { BsDot } from "react-icons/bs";
import ValidateOtpInput from '../../Components/GiftCards/ValidateOtpInput';
import ValidationViaCard from '../../Components/GiftCards/ValidationViaCard';
import ValidationViaPhone from '../../Components/GiftCards/ValidationViaPhone';
import AuthContext from '../../store/auth-context';
import { useNavigate } from 'react-router';
import { BASE_URL_API } from '../../References/constants';
import MainLoader from '../../Components/UI/MainLoader';

const VerifyCards = props => {
    const authCtx = useContext(AuthContext);
    const [responseTxt, setResponseTxt] = useState('')

    const navigate = useNavigate();
    if (authCtx.isLoggedIn === true) {
        navigate("/gc/my-cards")
    }


    const [modeOfVerification, setModeOfVerification] = useState('CARD')
    const [otpRequested, setOtpRequested] = useState(false);
    const [otpSendStatus, setOtpSendStatus] = useState('SENT'); //SENT, SENDING , INVALID_CREDENTIALS
    const [sessionKey, setSessionKey] = useState('');
    const [requestCount, setRequestCount] = useState(0);
    const [otpSentOn, setOtpSentOn] = useState('')

    const [secondsLeft, setSecondsLeft] = useState(45)
    useEffect(() => {
        const interval = setInterval(() => {
            setSecondsLeft((p) => p - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [])


    const toggleModeOfVerification = (k) => {
        setOtpRequested(false)
        setModeOfVerification(k);
    }
    const sendOtpHandler = (k, n) => {
        if (k === false) {
            setOtpRequested(false);
        } else {
            if (n !== undefined) {
                sendOtp(n);
                setSecondsLeft(45)
            } else {
                console.log("Invalid phone, try again later!")
            }
            console.log(n, "ok")
        }
    }

    const sendOtp = async (n) => {
        if (n !== 'undefined') {
            setOtpSendStatus('SENDING')
            const sendOtpResponse = await fetch(BASE_URL_API + `/auth`,
                {
                    method: `POST`,
                    body: JSON.stringify({
                        actionType: 'sendOtp',
                        phone: n,
                        requestCount: requestCount,
                        sessionKey: sessionKey
                    })
                });

            if (!sendOtpResponse.ok) {
                console.log("Something went wrong : Server Error");
            } else {
                const sendOtpRespo = await sendOtpResponse.json();
                if (sendOtpRespo.status === 'success') {
                    setRequestCount(sendOtpRespo.requestCount);
                    setSessionKey(sendOtpRespo.sessionKey);
                    setOtpSentOn(sendOtpRespo.phone)
                    setOtpRequested(true);
                    setOtpSendStatus('SENT')
                    setResponseTxt('')
                } else {
                    setOtpSendStatus('INVALID_CREDENTIALS')
                    setResponseTxt("You have entered an invalid number, please try again with valid number.")
                    setOtpRequested(true);
                    if (sendOtpRespo.status === 'failed') {

                    } else {
                        console.log(sendOtpRespo);
                    }
                }
            }
        } else {
            console.log("Invalid phone number served, please try again later.")
        }
        console.log(n)
    }




    return (
        <div className={styles.mainWrapper}>
            <MainLoader isVisible={otpSendStatus==='SENDING' ? true : false} />
            <GiftCardVerifyHeader />
            <div className={styles.mainContainer}>
                <div className={styles.upperSection}>
                    <div className={styles.heroTitleWrapper}>
                        <div className={styles.heroTitle}>
                            <h3>Everything is Here!</h3>
                        </div>
                        <div className={styles.heroSubTitle}>
                            About Your Lokaci Cards!
                        </div>
                    </div>
                </div>
                <div className={styles.showPowerSection}>
                    <div className={styles.eachPowerWrapper}>
                        <BsDot size={34} color="green" />
                        See transactions in seconds!
                    </div>
                    {/* <div className={styles.eachPowerWrapper}>
                        <BsDot size={34} color="green"/>
                        Check balance in 1 click!
                    </div>
                    <div className={styles.eachPowerWrapper}>
                        <BsDot size={34} color="green"/>
                        Get ultra savings with cards!
                    </div> */}
                </div>
                {/* <div className={styles.lineContainer}>
                    <div style={{ position: 'absolute', right: '100%', animation: 'slide-in 4s ease-out forwards infinite' }}>Line 1</div>
                    <div style={{ position: 'absolute', right: '100%', animationDelay: '4s', animation: 'slide-in 4s ease-out forwards infinite' }}>Line 2</div>
                    <div style={{ position: 'absolute', right: '100%', animationDelay: '8s', animation: 'slide-in 4s ease-out forwards infinite' }}>Line 3</div>
                </div> */}
                <div className={styles.howYouWantToLoginWrapper}>
                    <div className={styles.hywtlContainer}>
                        <div className={styles.hywtlTitle}>
                            Choose how you want to login!
                        </div>
                        <div className={styles.hywtlOptions}>
                            <button className={modeOfVerification === 'CARD' ? styles.eachOptionSelected : styles.eachOption}
                                onClick={() => toggleModeOfVerification('CARD')}
                            >
                                Via Card
                            </button>
                            <button className={modeOfVerification === 'MOBILE' ? styles.eachOptionSelected : styles.eachOption}
                                onClick={() => toggleModeOfVerification('MOBILE')}
                            >
                                Via Mobile
                            </button>
                        </div>
                    </div>
                </div>

                <div className={styles.mainBodySection}>
                    {modeOfVerification === 'CARD' ?
                        <ValidationViaCard
                            otpRequested={otpRequested}
                            sendOtpHandler={sendOtpHandler}
                        />
                        :
                        <ValidationViaPhone
                            otpRequested={otpRequested}
                            sendOtpHandler={sendOtpHandler}
                        />
                    }
                    {otpRequested === true &&
                        <div className={styles.validateOtpSection}>
                            {otpSendStatus === 'SENDING' ?
                                <div>
                                    <div>
                                        Hold On!
                                    </div>
                                    <div>
                                        We are just sending the OTP
                                    </div>
                                </div>
                                :
                                otpSendStatus === 'INVALID_CREDENTIALS' ?
                                    <div className={styles.invalidCredentialsWrapper}>
                                        <div className={styles.oopsTxt}>
                                            Oops,
                                        </div>
                                        <div className={styles.oopsMsg}>
                                            You have entered an invalid number.
                                        </div>
                                    </div>
                                    :
                                    <ValidateOtpInput
                                        secondsLeft={secondsLeft}
                                        sendOtpHandler={sendOtpHandler}
                                        phone={otpSentOn}
                                        sessionKey={sessionKey}
                                    />

                            }

                        </div>
                    }
                </div>
            </div >
            <div className={styles.footerWrapper}>
                Powered By Lokaci
            </div>
        </div >
    )
}

export default VerifyCards